import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSearchParams } from 'react-router-dom'; // React Router v6 以降
import styled from 'styled-components';

import Header from 'components/organisms/Header';
import Main from 'components/organisms/Main';
import Step1 from 'components/organisms/Step1';
import Step2 from 'components/organisms/Step2';
import Step3 from 'components/organisms/Step3';
import Step4 from 'components/organisms/Step4';
import Step5 from 'components/organisms/Step5';
import Step6 from 'components/organisms/Step6';

const ContentLayout = styled.div`
    background-color: var(--bs-white);
    border-radius: 1.5rem;
    padding: 3rem;
    @media screen and (max-width: 768px) {
        background-color: var(--bs-light);
        padding: 0;
        padding-bottom: 1.5rem;
        border-radius: 0;
    }
`;

// ステップの定数化
const STEPS = {
  INTRODUCTION: 1,
  TERMS: 2,
  TEST_QUESTION: 3,
  START: 4,
  INTERVIEW: 5,
  COMPLETE: 6,
} as const;

// 型定義
type Step = typeof STEPS[keyof typeof STEPS];

const Interview = () => {
    const [searchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<Step>(STEPS.INTRODUCTION);

    useEffect(() => {
        const stepFromCookie = Cookies.get('interview-step');
        if (stepFromCookie) {
            setCurrentStep(STEPS.INTERVIEW);
        }
    }, []);

    const renderScreen = () => {
        switch (currentStep) {
        case STEPS.INTRODUCTION:
            return (
                <>
                    <h1 className="h4 mb-3">はじめに</h1>
                    <ContentLayout>
                        <Step1 onNext={() => setCurrentStep(STEPS.TERMS)} />
                    </ContentLayout>
                </>
            );
        case STEPS.TERMS:
            return (
                <>
                    <h1 className="h4 mb-3">「Track AI Interview（β版）」受験者利用規約</h1>
                    <ContentLayout>
                        <Step2 onNext={() => setCurrentStep(STEPS.TEST_QUESTION)} />
                    </ContentLayout>
                </>
            );
        case STEPS.TEST_QUESTION:
            return (
                <>
                    <h1 className="h4 mb-3">テスト質問</h1>
                    <ContentLayout>
                        <Step3 onNext={() => setCurrentStep(STEPS.START)} />
                    </ContentLayout>
                </>
            );
        case STEPS.START:
            return (
                <>
                    <h1 className="h4 mb-3">面接を開始する</h1>
                    <ContentLayout>
                        <Step4 onNext={() => setCurrentStep(STEPS.INTERVIEW)} />
                    </ContentLayout>
                </>
            );
        case STEPS.INTERVIEW:
            return (
                <>
                    <h1 className="h4 mb-3">面接</h1>
                    <ContentLayout>
                        <Step5 onNext={() => setCurrentStep(STEPS.COMPLETE)} />
                    </ContentLayout>
                </>
            );
        case STEPS.COMPLETE:
            return (
                <>
                    <h1 className="h4 mb-3">面接終了</h1>
                    <ContentLayout>
                        <Step6 />
                    </ContentLayout>
                </>
            );
        default:
            return null;
        }
    };
    return (
        <>
            <Header />
            <Container className="pb-md-5">
                <Main>
                    <Row className="justify-content-center">
                        <Col md={10} lg={8}>
                            {renderScreen()}
                        </Col>
                    </Row>
                </Main>
            </Container>
        </>
    );
};

export default Interview;
