import React, { useState, useMemo, useRef, useCallback } from 'react';
import type { ReactNode } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Icon from 'components/atoms/ReactBootstrapIcon';

interface QuestionData {
  video_path: string;
  no: string;
  subno: string;
  question: string;
  theme: string;
  subtheme: string;
}

interface DataItem {
  video_url: string;
  transcript: string;
  no: number;
  question: QuestionData;
}

interface Interviewee {
  name: string;
  email: string;
  affiliation: string;
  status: string;
  status_txt: string;
  passed: string;
}

interface Props {
  meet_id: string;
  children?: ReactNode;
  data: DataItem[];
  question_category: string[];
  summary: string;
  evaluation: string;
  givery: string;
  fb: string;
  interviewee: Interviewee;
  onStatusUpdate: (status: string) => void;
  onDelete: (meetId: string) => Promise<void>;
  question_no: number;
  question_text: string;
}

const MyTab = (props: Props) => {
  const { meet_id, children, data, question_category, summary, evaluation, givery, fb, interviewee: initialInterviewee, onStatusUpdate, onDelete, question_no, question_text } = props;

  const [key, setKey] = useState<string>('basic');
  const [selectedCategory, setSelectedCategory] = useState<string>(question_category[0]);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [interviewee, setInterviewee] = useState<Interviewee>(initialInterviewee);

  const filteredData = useMemo(() => {
    return data.filter(item => item.question.theme === selectedCategory);
  }, [data, selectedCategory]);

  const handleTranscriptClick = (videoUrl: string) => {
    if (videoRef.current) {
      videoRef.current.src = videoUrl;
    }
  };

  const handleQuestionClick = (videoPath: string) => {
    if (videoRef.current) {
      videoRef.current.src = videoPath;
    }
  };

  const passedOptions = [
    { value: 0, label: "評価待ち" },
    { value: 1, label: "不合格" },
    { value: 2, label: "合格" },
  ];

  const getPassedLabel = (value: number) => {
    return passedOptions.find(option => option.value === value)?.label || "不明";
  };

  const handleStatusChange = useCallback((eventKey: string | null) => {
    if (!eventKey) return;
    
    const updateStatus = async () => {
      const result = onStatusUpdate(eventKey);
      const newPassedText = getPassedLabel(Number(eventKey));
      
      setInterviewee(prevInterviewee => ({
        ...prevInterviewee,
        passed: eventKey,
        passed_txt: newPassedText
      }));

      return result;
    };

    updateStatus().catch(error => {
      console.error('Error updating status:', error);
    });
  }, [onStatusUpdate]);

  const handleDelete = useCallback(async () => {
    if (window.confirm('本当に削除しますか？')) {
      try {
        await onDelete(meet_id);
      } catch (error) {
        console.error('Error deleting interview:', error);
      }
    }
  }, [meet_id, onDelete]);

  return (
    <>
      <Accordion.Body>
        <Tab.Container activeKey={key} onSelect={(k) => k && setKey(k)}>
          <Nav variant="underline" defaultActiveKey="basic">
            <Nav.Item>
              <Nav.Link eventKey="basic">基本情報</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="summary">要約</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="log">収録</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fb">候補者FB</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="py-2">
            <Tab.Pane eventKey="basic">
              <div className="text-muted overflow-auto bg-light p-3 rounded" style={{ height: '480px', fontSize: '0.85em' }}>
                <dl>
                  <dt>id</dt>
                  <dd>{meet_id}</dd>
                  <dt>質問番号</dt>
                  <dd>{question_text} ({question_no})</dd>

                  <dt>名前</dt>
                  <dd>{interviewee.name}</dd>

                  <dt>所属</dt>
                  <dd>{interviewee.affiliation}</dd>

                  <dt>メアド</dt>
                  <dd>{interviewee.email}</dd>

                  <dt>ユニーク面接リンク</dt>
                  <dd><a href={`${process.env.PUBLIC_URL ?? ''}/?mid=${meet_id}`} target='_blank' rel="noreferrer">面接リンク<Icon iconName="Link45deg" /></a></dd>

                  <dt>受験ステータス</dt>
                  <dd>{interviewee.status_txt}</dd>

                  <dt>合格判定</dt>
                  <dd>
                    <Dropdown onSelect={handleStatusChange}>
                      <Dropdown.Toggle variant="secondary" id="passed" size="sm">
                        {getPassedLabel(Number(interviewee.passed))}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {passedOptions.map((item) => (
                          <Dropdown.Item
                            key={item.value}
                            eventKey={item.value.toString()}
                            active={Number(interviewee.passed) === item.value}
                          >
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </dd>
                </dl>
                <div className="d-flex mt-3">
                  <button 
                    className="btn btn-danger btn-sm"
                    onClick={handleDelete}
                  >
                    <Icon iconName="Trash" /> 削除
                  </button>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="summary">
              <div className="text-muted overflow-auto bg-light p-3 rounded" style={{ height: '580px', fontSize: '0.85em' }}>
                <h3>要約</h3>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{summary}</ReactMarkdown>
                <h3>独自評価項目</h3>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{givery}</ReactMarkdown>
                <h3>総合評価</h3>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{evaluation}</ReactMarkdown>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="log">
              <Dropdown className="d-flex justify-content-end" align="end">
                <Dropdown.Toggle variant="secondary" size="sm">
                  質問一覧
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {question_category.map((category, index) => (
                    <Dropdown.Item 
                      key={index} 
                      onClick={() => setSelectedCategory(category)}
                    >
                      <small>{category}</small>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <div className="mt-3">
                <Row className="justify-content-center mb-4">
                  <Col md="4" className="mb-3 mb-md-0">
                    <div className="h6">動画</div>
                    {filteredData.length > 0 && (
                      <video ref={videoRef} controls src={filteredData[0].video_url} className="w-100 h-auto" />
                    )}
                  </Col>
                  <Col md="8">
                    <div className="text-muted overflow-auto bg-light p-3 rounded" style={{ height: '580px' }}>
                      {filteredData.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="w-75 mb-3 bg-white p-2 rounded"
                            onClick={() => handleQuestionClick(item.question.video_path)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div><small>面接官</small></div>
                            <p className="mb-0"><small>{item.question.question}</small></p>
                          </div>
                          <div 
                            className="w-75 ms-auto mb-3 bg-white p-2 rounded" 
                            onClick={() => handleTranscriptClick(item.video_url)}
                            style={{ cursor: 'pointer' }}
                          >
                            <div className="text-end"><small>あなた</small></div>
                            <p className="mb-0"><small>{item.transcript}</small></p>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fb">
              <div className="text-muted overflow-auto bg-light p-3 rounded" style={{ height: '580px', fontSize: '0.85em' }}>
                <h3>候補者FB</h3>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{fb}</ReactMarkdown>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Accordion.Body>
    </>
  );
}

export default MyTab;
