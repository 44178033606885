/* eslint-disable import/prefer-default-export */

import type { AxiosResponse } from 'axios';
import axios from 'axios';

import endpointUrl from 'apis/endpoint';
import { ApiError, UnknownError } from 'errors';


/**
 * 質問文の取得
 * @description PoCでは特に制限なく取得可能
 * @returns
 */
export const fetchQuestions = async (meetId: string) => {
    try {
        const res: AxiosResponse = await axios.get(endpointUrl(`/api/menu?meetId=${meetId}`));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = res.data || {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
    } catch (error) {
        // AxiosError型のエラーかどうかを確認
        if (axios.isAxiosError(error)) {
            // AxiosError型としてエラーオブジェクトにアクセスできる
            // const axiosError: AxiosError = error;

            // エラーのステータスコードや詳細な情報にアクセス
            // console.error('AxiosError:', axiosError.response?.status, axiosError.response?.data);
            throw new ApiError('Fetch failed');
        } else {
            // それ以外のエラーの処理
            // console.error(error);
            throw new UnknownError('Unknown Error');
        }
    }
};
