import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Admin from 'components/pages/Admin';
import Interview from 'components/pages/Interview';
import Login from 'components/pages/Login';

const App = () => (
    <div className="bg-light min-vh-100">
        <Router>
            <Routes>
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Interview />} />
            </Routes>
        </Router>
    </div>
);

export default App;
