import { useState, type ChangeEvent, type FormEvent } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

interface CreateInterviewProps {
  onClose: () => void;
}

interface InterviewData {
    name: string;
    affiliation: string;
    email: string;
    status: '0' | '1' | '2' | '9';
    question_no: number;
}

interface ApiResponse {
    status: 'ok' | 'error';
    message?: string;
    id?: string;
}

const CreateInterview: React.FC<CreateInterviewProps> = ({ onClose }) => {
    const [interviewData, setInterviewData] = useState<InterviewData>({
        name: '',
        affiliation: '',
        email: '',
        status: '0',
        question_no: 1,
    });
    const [message, setMessage] = useState<string | null>(null);
    const [isError, setIsError] = useState(false);
    const [interviewUrl, setInterviewUrl] = useState<string | null>(null);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const newValue = name === 'question_no' ? parseInt(value, 10) : value;
        setInterviewData(prev => ({ ...prev, [name]: newValue }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setMessage(null);
        setIsError(false);
        setInterviewUrl(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT ?? ''}/admin/interview/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(interviewData),
            });

            const data: ApiResponse = await response.json();

            if (data.status === 'ok' && data.id) {
                setMessage('面談が正常に作成されました。');
		const url = `https://interview.tracks.run${process.env.PUBLIC_URL ?? ''}/?mid=${data.id}`;
                setInterviewUrl(url);
            } else {
                setIsError(true);
                setMessage(data.message || '面談の作成に失敗しました。');
            }
        } catch (error) {
            setIsError(true);
            setMessage('エラーが発生しました。もう一度お試しください。');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {message && (
                <Alert variant={isError ? 'danger' : 'success'}>
                    {message}
                </Alert>
            )}
            {interviewUrl && (
                <Alert variant="info">
                    面談URL: <a href={interviewUrl} target="_blank" rel="noopener noreferrer">{interviewUrl}</a>
                    <Button variant="outline-secondary" size="sm" className="ms-2" onClick={() => navigator.clipboard.writeText(interviewUrl)}>
                        URLをコピー
                    </Button>
                </Alert>
            )}
            <Form.Group className="mb-3">
                <Form.Label>名前</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={interviewData.name}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>所属</Form.Label>
                <Form.Control
                    type="text"
                    name="affiliation"
                    value={interviewData.affiliation}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>質問番号</Form.Label>
                <Form.Select
                    name="question_no"
                    value={interviewData.question_no}
                    onChange={handleChange}
                    required
                >
                    <option value="1">新卒ver1</option>
                    <option value="2">中途</option>
                    <option value="3">新卒ver2</option>
                    <option value="4">Job Agent</option>
                    <option value="5">新卒ver3</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={interviewData.email}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>受験ステータス</Form.Label>
                <Form.Select
                    name="status"
                    value={interviewData.status}
                    onChange={handleChange}
                    required
                >
                    <option value="0">未受験</option>
                    <option value="1">受験中</option>
                    <option value="2">受験済み</option>
                    <option value="9">キャンセル</option>
                </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit">
                面談を作成
            </Button>
        </Form>
    );
};

export default CreateInterview;
