import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';


const AdminHeader = () => {
  const navigate = useNavigate();

  return (
    <Navbar expand="md" sticky="top" className="bg-body mb-4 mb-md-5">
      <Container>
        <Navbar.Brand href="#/admin">
          <Image
            src={`${process.env.PUBLIC_URL}/logo2.png`}
            width="30"
            style={{ marginTop: '5px', marginRight: '5px' }}
            className="d-inline-block align-top"
            alt="Logo"
          />{' '}
          Track AI Interview (管理画面)
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default AdminHeader;
