import { useEffect, useState, useCallback } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import { useNavigate, Routes, Route } from 'react-router-dom';

import { checkAdminLogin, adminLogout, interviewUpdatePassedStatus, interviewDelete } from 'apis/fetch/admin';
import Tab from 'components/molecules/Tab';
import AdminHeader from 'components/organisms/AdminHeader';
import AdminUserManager from 'components/organisms/AdminUserManager';
import CreateInterview from 'components/organisms/CreateInterview';
import Main from 'components/organisms/Main';
import { adminReportListFetchData } from 'utils/api';

interface DataItem {
  video_url: string;
  transcript: string;
  no: number;
  question: {
    video_path: string;
    no: string;
    subno: string;
    question: string;
    theme: string;
    subtheme: string;
  };
}

interface Interviewee {
  name: string;
  email: string;
  affiliation: string;
  status: string;
  status_txt: string;
  passed: string;
}

interface Item {
  meet_id: string;
  ts: number;
  data: DataItem[];
  question_category: string[];
  summary: string;
  evaluation: string;
  givery: string;
  interviewee: Interviewee;
  question_no: number;
  question_text: string;
}

const Admin = () => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [adminReportList, setAdminReportList] = useState<Item[]>([]);
  const [isCreatingInterview, setIsCreatingInterview] = useState(false);
  const [showUserManager, setShowUserManager] = useState(false);
  const [currentPassedVal, setCurrentPassedVal] = useState(-1);
  const navigate = useNavigate();

  const fetchData = useCallback(async (passed_val = -1) => {
    try {
      console.log('Fetching admin report list...');
      await adminReportListFetchData(setAdminReportList, passed_val);
      setCurrentPassedVal(passed_val);
    } catch (error) {
      console.error('Failed to fetch admin report list:', error);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    await adminLogout();
    navigate('../login');
  }, [navigate]);

  const handleCreateInterview = useCallback(() => {
    setIsCreatingInterview(true);
  }, []);

  const handleShowUserManager = useCallback(() => {
    navigate('users');
  }, [navigate]);

  const handleBackToList = useCallback(() => {
    setIsCreatingInterview(false);
    navigate('/admin');
    fetchData();
  }, [navigate]);

  const handlePassedStatusUpdate = useCallback((meetId: string) => (newStatus: string) => {
    interviewUpdatePassedStatus(meetId, newStatus);
    console.log(`Updating status for meet_id: ${meetId} to ${newStatus}`);
  }, []);

  const handleShowPassedList = useCallback((passed_val: number) => {
    console.log(`Fetching data for passed_val: ${passed_val}`);
    fetchData(passed_val);
  }, [fetchData]);

  const handleDeleteInterview = useCallback((meetId: string) => async () => {
    await interviewDelete(meetId);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const initializeAdmin = async () => {
      try {
        console.log('Checking admin login...');
        const isLoggedIn = await checkAdminLogin();
        setIsAdminLoggedIn(isLoggedIn);
        if (!isLoggedIn) {
          navigate('../login');
        } else {
          console.log('Admin logged in, fetching data...');
          await fetchData();
        }
      } catch (error) {
        console.error('Failed to initialize admin:', error);
        navigate('../login');
      }
    };

    initializeAdmin();
  }, [navigate, fetchData]);

  useEffect(() => {
    console.log('Admin report list updated:', adminReportList);
  }, [adminReportList]);

  if (!isAdminLoggedIn) {
    return null; // または適切なローディング表示
  }

  return (
    <>
      <AdminHeader />
      <Container className="pb-md-5">
        <Main>
          <Routes>
            <Route path="users" element={<AdminUserManager />} />
            <Route path="/" element={
              <>
                <Row className="align-items-center mb-3">
                  <Col>
                    <div className="h4 mb-0">面談者一覧</div>
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => handleShowPassedList(0)}
                      className="me-2"
                      variant={currentPassedVal === 0 ? "primary" : "outline-primary"}
                    >
                      評価待ち
                    </Button>
                    <Button
                      onClick={() => handleShowPassedList(1)}
                      className="me-2"
                      variant={currentPassedVal === 1 ? "primary" : "outline-primary"}
                    >
                      不合格者
                    </Button>
                    <Button
                      onClick={() => handleShowPassedList(2)}
                      className="me-2"
                      variant={currentPassedVal === 2 ? "primary" : "outline-primary"}
                    >
                      合格者
                    </Button>
                    <Button variant="primary" onClick={handleCreateInterview} className="me-2">
                      面談を作成
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Dropdown align="end">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        ⚙️
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShowUserManager}>Adminユーザ管理</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>ログアウト</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                {isCreatingInterview ? (
                  <>
                    <Row className="align-items-center mb-3">
                      <Col>
                        <div className="h4 mb-0">面談を作成</div>
                      </Col>
                      <Col xs="auto">
                        <Button variant="secondary" onClick={handleBackToList}>
                          一覧に戻る
                        </Button>
                      </Col>
                    </Row>
                    <CreateInterview onClose={handleBackToList} />
                  </>
                ) : (
                  <Row className="justify-content-center">
                    <Col>
                      <Accordion className="row gy-3" alwaysOpen>
                        {adminReportList.map((item, index) => (
                          <Col xs="12" key={item.meet_id}>
                            <Accordion.Item eventKey={index.toString()}>
                              <Accordion.Header>{item.interviewee.name} ({item.interviewee.status_txt})</Accordion.Header>
                              <Accordion.Body>
                                <Tab 
                                  meet_id={item.meet_id} 
                                  data={item.data} 
                                  question_category={item.question_category} 
                                  summary={item.summary} 
                                  evaluation={item.evaluation}
                                  givery={item.givery}
                                  interviewee={item.interviewee}
                                  onStatusUpdate={handlePassedStatusUpdate(item.meet_id)}
                                  onDelete={handleDeleteInterview(item.meet_id)}
                                  question_no={item.question_no}
                                  question_text={item.question_text}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                )}
              </>
            } />
          </Routes>
        </Main>
      </Container>
    </>
  );
};

export default Admin;
