import { useEffect, useState } from 'react';
import { Table, Button, Container, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // 追加

import { fetchUsers, deleteUser, AdminUser, addUser } from 'apis/fetch/admin'; // addUser を追加

const AdminUserManager = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // 追加
  const [showAddModal, setShowAddModal] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newUserInfo, setNewUserInfo] = useState<{ email: string; password: string } | null>(null);

  const fetchUserData = async () => {
    try {
      const userData = await fetchUsers();
      setUsers(userData);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: number) => {
    if (window.confirm('このユーザーを削除しますか？')) {
      try {
        await deleteUser(userId);
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Failed to delete user:', error);
      }
    }
  };

  const handleBackToList = () => {
    navigate('/admin', { replace: true });
  };

  const handleAddUser = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await addUser(newEmail);
      const newUser = {
        id: response.id,
        email: response.email,
        password: response.password
      };
      setUsers(prevUsers => [...prevUsers, newUser]);
      setShowAddModal(false);
      setNewEmail('');
      prompt('ユーザーが追加されました, パスワード:', newUser.password);
      setNewUserInfo({ email: newUser.email, password: newUser.password });
    } catch (error) {
      console.error('Failed to add user:', error);
    }
  };

  const handleCopyUserInfo = () => {
    if (newUserInfo) {
      const userInfoText = `Email: ${newUserInfo.email}\nPassword: ${newUserInfo.password}`;
      navigator.clipboard.writeText(userInfoText)
        .then(() => alert('ユーザー情報がクリップボードにコピーされました'))
        .catch(err => console.error('コピーに失敗しました:', err));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Adminユーザー管理</h4>
        <div>
          <Button variant="secondary" onClick={handleBackToList} className="me-2">一覧に戻る</Button>
          <Button variant="success" onClick={() => setShowAddModal(true)} className="me-2">追加</Button>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '100px' }}>ID</th>
            <th>メール</th>
            <th style={{ width: '100px' }}>アクション</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.email}</td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteUser(user.id)}>
                  削除
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>新規ユーザー追加</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddUser}>
            <Form.Group className="mb-3">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
            </Form.Group>
            {/* Remove the password input Form.Group */}
            <Button variant="primary" type="submit">
              追加
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      
      {newUserInfo && (
        <div className="modal">
          <div className="modal-content">
            <h2>新しいユーザーが追加されました</h2>
            <p>Email: {newUserInfo.email}</p>
            <p>Password: {newUserInfo.password}</p>
            <button onClick={handleCopyUserInfo}>情報をコピー</button>
            <button onClick={() => setNewUserInfo(null)}>閉じる</button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AdminUserManager;