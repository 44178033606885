import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';

import { adminLogin } from 'apis/fetch/admin';
import AdminHeader from 'components/organisms/AdminHeader';
import Main from 'components/organisms/Main';

const Login = () => {
  const [org, setOrg] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message

    if (!org || !email || !password) {
      setErrorMessage('組織ID、メールアドレス、パスワードを入力してください。');
      return;
    }

    if (password.length < 12) {
      setErrorMessage('パスワードは12文字以上で入力してください。');
      return;
    }

    try {
      const isLoggedIn = await adminLogin(org, email, password);
      if (isLoggedIn) {
        navigate('../admin');
      } else {
        setErrorMessage('ログインに失敗しました。');
      }
    } catch (error) {
      setErrorMessage('ログインエラーが発生しました。');
    }
  };

  return (
    <>
      <AdminHeader />
      <Container className="pb-md-5">
        <Main>
          <Row className="justify-content-center">
            <Col md={6}>
              <h2 className="mb-3">adminログイン</h2>
              {errorMessage && (
                <p className="text-danger">{errorMessage}</p>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicOrg">
                  <Form.Label>組織ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="組織IDを入力"
                    value={org}
                    onChange={(e) => setOrg(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>メールアドレス</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="メールアドレスを入力"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>パスワード</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="パスワードを入力"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Text className="text-muted">
                    パスワードは12文字以上で入力してください。
                  </Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit">
                  ログイン
                </Button>
              </Form>
            </Col>
          </Row>
        </Main>
      </Container>
    </>
  );
};

export default Login;
