import Cookies from 'js-cookie';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Ratio from 'react-bootstrap/Ratio';
import Row from 'react-bootstrap/Row';

import Question from 'components/atoms/Question';
import Icon from 'components/atoms/ReactBootstrapIcon';
import { questionsFetchData } from 'utils/api';
import { getMediaRecorderOptions } from 'utils/util';

type Props = {
    onNext: () => void;
};

type QuestionData = {
    no: string;
    theme: string;
    subtheme: string;
    question: string;
    video_path?: string;
};

const DynamicOffcanvas: React.FC<{
    show: boolean;
    handleClose: () => void;
    questionsData: QuestionData[];
    currentQuestionNo: string;
}> = ({ show, handleClose, questionsData, currentQuestionNo }) => {
    const groupedQuestions = useMemo(() => {
        return questionsData.reduce<Record<string, QuestionData[]>>((acc, question) => {
            if (!acc[question.theme]) {
                acc[question.theme] = [];
            }
            acc[question.theme].push(question);
            return acc;
        }, {});
    }, [questionsData]);

    return (
        <Offcanvas show={show} onHide={handleClose} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>質問一覧</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {Object.entries(groupedQuestions).map(([theme, questions], index) => (
                    <div key={index}>
                        <div className="h6">{`${index + 1}.${theme}`}</div>
                        <ol>
                            {questions.map((question, qIndex) => (
                                <li key={qIndex} className={question.no === currentQuestionNo ? 'fw-bold' : ''}>
                                    {question.subtheme}
                                    {question.no === currentQuestionNo && (
                                        <span className="ms-2 text-primary">(現在の質問)</span>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </div>
                ))}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

const Step5: React.FC<Props> = ({ onNext }) => {
    const [showModal, setShowModal] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    let currentQuestionStep = '1'
    const meetId = Cookies.get('meet_id');
    if (meetId) {
        const cookieInterviewStep = Cookies.get('interview-step');
        if (cookieInterviewStep && cookieInterviewStep.indexOf(meetId) === 0) {
            const [, step] = cookieInterviewStep.split(':');
            currentQuestionStep = step;
        } else {
            Cookies.remove('interview-step');
        }
    }
    const [currentQuestion, setCurrentQuestion] = useState<string>(currentQuestionStep || '1');
    const [questionsData, setQuestionsData] = useState<QuestionData[]>([]);
    const [questionData, setQuestionData] = useState<QuestionData>({} as QuestionData);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedTime, setRecordedTime] = useState(0);
    const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
    const [subtitleProgress, setSubtitleProgress] = useState(0);
    const [isAIVideoFinished, setIsAIVideoFinished] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [isChangingStatus, setIsChangingStatus] = useState(false);
    const [statusChangeError, setStatusChangeError] = useState<string | null>(null);
    const [isStatusChanged, setIsStatusChanged] = useState(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const aiVideoRef = useRef<HTMLVideoElement>(null);
    const confirmVideoRef = useRef<HTMLVideoElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<number | null>(null);

    const changeInterviewStatus = useCallback(async () => {
        if (isStatusChanged) return;

        setIsChangingStatus(true);
        setStatusChangeError(null);

        const meetId = Cookies.get('meet_id');
        if (!meetId) {
            setStatusChangeError('面接IDが見つかりません。もう一度お試しください。');
            setIsChangingStatus(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/change_status?mid=${meetId}&status=1`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            });

            if (!response.ok) {
                throw new Error('面接ステータスの変更に失敗しました');
            }

            const data = await response.json();
            setIsStatusChanged(true);
        } catch (err) {
            setStatusChangeError('面接ステータスの変更中にエラーが発生しました。もう一度お試しください。');
        } finally {
            setIsChangingStatus(false);
        }
    }, [isStatusChanged]);

    useEffect(() => {
        const initializeInterview = async () => {
            await questionsFetchData(setQuestionsData);
            await changeInterviewStatus();
        };

        void initializeInterview();
    }, [changeInterviewStatus]);

    const playAIVideo = useCallback(() => {
        if (aiVideoRef.current) {
            aiVideoRef.current.currentTime = 0;
            setIsAIVideoFinished(false);
            aiVideoRef.current.play().catch(error => console.error("Error playing AI video:", error));
        }
    }, []);

    const handleAIVideoEnded = useCallback(() => {
        setIsAIVideoFinished(true);
    }, []);

    const updateSubtitleProgress = useCallback((currentSubtheme: string) => {
        const subthemes = questionsData.map(q => q.subtheme);
        const uniqueSubthemes = Array.from(new Set(subthemes));
        const currentSubthemeIndex = uniqueSubthemes.indexOf(currentSubtheme);
        const newProgress = ((currentSubthemeIndex + 1) / uniqueSubthemes.length) * 100;
        setSubtitleProgress(Math.round(newProgress));
    }, [questionsData]);

    useEffect(() => {
        const foundQuestion = questionsData.find(item => item.no === currentQuestion);
        if (foundQuestion) {
            setQuestionData(foundQuestion);
            if (aiVideoRef.current && foundQuestion.video_path) {
                aiVideoRef.current.src = foundQuestion.video_path;
                const handlePlay = () => {
                    setTimeout(() => {
                        aiVideoRef.current?.play().catch(() => {});
                    }, 500);
                };
                aiVideoRef.current.onloadeddata = handlePlay;
                return () => {
                    aiVideoRef.current?.removeAttribute('onloadeddata');
                };
            }
            updateSubtitleProgress(foundQuestion.subtheme);
            return;
        }
    }, [currentQuestion, questionsData, updateSubtitleProgress]);

    const setupMediaRecorder = useCallback((stream: MediaStream) => {
        const options: MediaRecorderOptions = getMediaRecorderOptions();
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunksRef.current.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const blob = new Blob(chunksRef.current, { type: options.mimeType || 'video/webm' });
            if (blob.size > 0) {
                const url = URL.createObjectURL(blob);
                setRecordedVideoURL(url);
                setShowModal(true);
            } else {
                console.error('Recording failed: no data was captured');
            }
            chunksRef.current = [];
        };
    }, []);

    useEffect(() => {
        const startWebcam = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setupMediaRecorder(stream);
            } catch (err) {
                console.error("Error accessing the webcam:", err);
            }
        };

        startWebcam();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
                tracks.forEach(track => track.stop());
            }
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [setupMediaRecorder]);

    const startRecording = useCallback(() => {
        if (mediaRecorderRef.current && !isRecording) {
            chunksRef.current = [];
            mediaRecorderRef.current.start();
            setIsRecording(true);
            setRecordedTime(0);
            setRecordedVideoURL(null);
            timerRef.current = window.setInterval(() => {
                setRecordedTime(prevTime => prevTime + 1);
            }, 1000);
        }
    }, [isRecording]);

    const stopRecording = useCallback(() => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    }, [isRecording]);

    const resetRecording = useCallback(() => {
        stopRecording();
        setRecordedTime(0);
        setRecordedVideoURL(null);
        chunksRef.current = [];
    }, [stopRecording]);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        resetRecording();
    }, [resetRecording]);

    const handleShowOffcanvas = useCallback(() => setShowOffcanvas(true), []);
    const handleCloseOffcanvas = useCallback(() => setShowOffcanvas(false), []);

    const handleRetakeAnswer = useCallback(() => {
        setShowModal(false);
        resetRecording();
        playAIVideo();
    }, [resetRecording, playAIVideo]);

    const sendRecording = useCallback(async (videoURL: string) => {
        if (!videoURL) {
            setUploadError('録画されたビデオがありません。もう一度録画してください。');
            return false;
        }
    
        setIsUploading(true);
        setUploadError(null);
    
        const maxRetries = 3;
        let currentRetry = 0;
    
        /* eslint-disable no-await-in-loop */
        while (currentRetry < maxRetries) {
            try {
                const existingMeetId = Cookies.get('meet_id');
                if (!existingMeetId) {
                    throw new Error('No meet_id cookie found');
                }
    
                const response = await fetch(videoURL);
                const blob = await response.blob();
    
                const formData = new FormData();
                formData.append('video', blob, 'recorded_video.webm');
                formData.append('meet_id', existingMeetId);
                formData.append('no', currentQuestion);
    
                const apiResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/record_video`, {
                    method: 'POST',
                    body: formData,
                });
    
                if (!apiResponse.ok) {
                    throw new Error(`HTTP error! status: ${apiResponse.status}`);
                }
    
                setIsUploading(false);
                return true;
            } catch (error) {
                currentRetry += 1; // Use += 1 instead of ++
                if (currentRetry === maxRetries) {
                    setUploadError('アップロードに失敗しました、撮り直してください');
                    setIsUploading(false);
                    return false;
                }
                // Wait before retrying
                await new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                });
            }
        }
        /* eslint-enable no-await-in-loop */
    
        setIsUploading(false);
        return false;
    }, [currentQuestion]);

    const handleCompleteAnswer = useCallback(async () => {
        if (!recordedVideoURL) {
            setUploadError('録画されたビデオがありません。もう一度録画してください。');
            return;
        }

        if (!isStatusChanged) {
            await changeInterviewStatus();
            if (!isStatusChanged) {
                setUploadError('面接ステータスの変更ができませんでした。管理者に連絡してください。');
                return;
            }
        }

        const uploadSuccess = await sendRecording(recordedVideoURL);

        if (uploadSuccess) {
            const currentIndex = questionsData.findIndex(q => q.no === currentQuestion);
            if (currentIndex < questionsData.length - 1) {
                const nextQuestion = questionsData[currentIndex + 1];
                const meetId = Cookies.get('meet_id');
                if (meetId) {
                    Cookies.set('interview-step', `${meetId}:${currentIndex + 2}`);
                }
                setCurrentQuestion(nextQuestion.no);
                setShowModal(false);
                resetRecording();
                playAIVideo();
            } else {
                onNext();
            }
        }
    }, [currentQuestion, questionsData, onNext, resetRecording, playAIVideo, sendRecording, recordedVideoURL, changeInterviewStatus, isStatusChanged]);

    const formatTime = useCallback((seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }, []);

    useEffect(() => {
        if (showModal && confirmVideoRef.current && recordedVideoURL) {
            confirmVideoRef.current.src = recordedVideoURL;
            confirmVideoRef.current.play().catch(error => console.error("Error playing video:", error));
        }
    }, [showModal, recordedVideoURL]);

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Ratio aspectRatio="16x9">
                        <video
                            ref={aiVideoRef}
                            autoPlay
                            onEnded={handleAIVideoEnded}
                            aria-hidden="true"
                        >
                            <source type="video/webm" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
                <Col>
                    <Ratio aspectRatio="16x9">
                        <video ref={videoRef} autoPlay playsInline muted aria-hidden="true">
                            <track kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <ProgressBar
                    now={subtitleProgress}
                    label={`${subtitleProgress}%`}
                    className="w-100 me-3"
                    variant="success"
                />
                <Button variant="primary" onClick={handleShowOffcanvas} size='sm' className="text-nowrap">質問一覧</Button>
                <DynamicOffcanvas
                    show={showOffcanvas}
                    handleClose={handleCloseOffcanvas}
                    questionsData={questionsData}
                    currentQuestionNo={currentQuestion}
                />
            </div>
            <div className="mb-4 h5">{questionData.theme} </div>
            <Question title={questionData.subtheme} question={questionData.question} />
            <div className="text-center mb-4 h4">
                <Icon className="me-2" iconName="Clock" />{formatTime(recordedTime)}
            </div>

            <div className="d-flex justify-content-center mb-3">
                <Button
                    variant="primary"
                    onClick={startRecording}
                    className="d-block me-2 rounded-5"
                    disabled={isRecording || !isAIVideoFinished}
                >
                    録画を開始
                </Button>
                <Button
                    variant="primary"
                    onClick={stopRecording}
                    className="d-block rounded-5"
                    disabled={!isRecording}
                >
                    録画を停止
                </Button>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>収録内容の確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div>問題がないか、収録内容をご確認ください。</div>
                        <Ratio aspectRatio="16x9">
                            <video ref={confirmVideoRef} controls playsInline aria-hidden="true">
                                <track kind="captions" />
                                Your browser does not support the video tag.
                            </video>
                        </Ratio>
                    </div>
                    {uploadError && <div className="text-danger mt-3">{uploadError}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleRetakeAnswer}>
                        撮り直す
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleCompleteAnswer}
                        disabled={isUploading || isChangingStatus}
                    >
                        {isUploading
                            ? '処理中'
                            : isChangingStatus
                                ? '処理中...'
                                : currentQuestion === questionsData[questionsData.length - 1]?.no
                                    ? '回答を完了する'
                                    : '次の質問へ'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            {statusChangeError && <div className="text-danger mt-3">{statusChangeError}</div>}
        </>
    );
};

export default Step5;

