import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Ratio from 'react-bootstrap/Ratio';
import Row from 'react-bootstrap/Row';

import Question from 'components/atoms/Question';
import Icon from 'components/atoms/ReactBootstrapIcon';
import { checkMeetId } from 'utils/api';
import { getMediaRecorderOptions } from 'utils/util';

type Props = {
    onNext: () => void;
};

const Step3 = ({ onNext }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedTime, setRecordedTime] = useState(0);
    const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const aiVideoRef = useRef<HTMLVideoElement>(null);
    const confirmVideoRef = useRef<HTMLVideoElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
        const validateMeetId = async () => {
            const meetId = Cookies.get('meet_id');
            if (!meetId) {
                Cookies.remove('meet_id');
                window.location.reload();
                return;
            }

            try {
                const question_no = await checkMeetId(meetId);
                if (!question_no) {
                    Cookies.remove('meet_id');
                    window.location.reload();
                }
            } catch (error) {
                Cookies.remove('meet_id');
                window.location.reload();
            }
        };

        validateMeetId();
    }, []);

    const setupMediaRecorder = (stream: MediaStream) => {
        const options: MediaRecorderOptions = getMediaRecorderOptions();
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunksRef.current.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const blob = new Blob(chunksRef.current, { type: options.mimeType || 'video/webm' });
            if (blob.size > 0) {
                const url = URL.createObjectURL(blob);
                setRecordedVideoURL(url);
            } else {
                setRecordedVideoURL(null);
            }
            chunksRef.current = [];
        };
    };

    useEffect(() => {
        let stream: MediaStream | null = null;

        const startWebcam = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setupMediaRecorder(stream);
            } catch (err) {
                console.error("Error accessing the webcam:", err);
            }
        };

        startWebcam().catch((err) => {
            console.error("Failed to start webcam:", err);
        });

        return () => {
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    const startRecording = () => {
        if (mediaRecorderRef.current && !isRecording) {
            mediaRecorderRef.current.start();
            setIsRecording(true);
            setRecordedTime(0);
            timerRef.current = window.setInterval(() => {
                setRecordedTime(prevTime => prevTime + 1);
            }, 1000);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            if (timerRef.current) {
                clearInterval(timerRef.current);
                setShowModal(true);
            }
        }
    };

    const resetRecording = () => {
        stopRecording();
        setRecordedTime(0);
        setRecordedVideoURL(null);
        chunksRef.current = [];
    };

    const handleAiVideoEnded = () => {
        startRecording();
    };

    const handleShowModal = () => {
        stopRecording();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        resetRecording();
        if (aiVideoRef.current) {
            aiVideoRef.current.currentTime = 0;
            aiVideoRef.current.play().catch(error => console.error("Error playing AI video:", error));
        }
    };

    const formatTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        if (showModal && confirmVideoRef.current && recordedVideoURL) {
            confirmVideoRef.current.src = recordedVideoURL;
            confirmVideoRef.current.play().catch(error => console.error("Error playing video:", error));
        }
    }, [showModal, recordedVideoURL]);

    return (
        <>
            <p className="mb-4">まずはマイクとカメラのテストをしましょう</p>
            <Row className='mb-4'>
                <Col>
                    <Ratio aspectRatio="16x9">
                        <video 
                            ref={aiVideoRef}
                            autoPlay 
                            onEnded={handleAiVideoEnded}
                            aria-hidden="true" 
                            id="ai_staff"
                        >
                            <source src={`${process.env.REACT_APP_API_ENDPOINT}/static/mp4s/question1/intro.mp4.webm`} type="video/webm" />
                            <track kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
                <Col>
                    <Ratio aspectRatio="16x9">
                        <video ref={videoRef} autoPlay playsInline muted aria-hidden="true" id="preview_webcamera">
                            <track kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
            </Row>
            <Question title="ご質問" question="この質問はマイクとカメラが機能しているかを確認するテスト質問です。
試しに、あなたの好きな⾷べ物を教えてもらえますか？" />
            <div className="text-center mb-4 h4">
                <Icon className="me-2" iconName="Clock" />{formatTime(recordedTime)}
            </div>

            <Button 
                variant="primary" 
                onClick={handleShowModal} 
                className="mx-auto d-block"
                disabled={!isRecording && recordedTime === 0}
            >
                回答を確認する
            </Button>
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>収録内容の確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <p>インタビュー前に問題がないか、収録内容をご確認ください。</p>
                        <p className="text-danger">インタビュー開始後でも中断することは可能ですが、タイマーは停止されません。このタイミングで動作環境をご確認ください。</p>
                        <Ratio aspectRatio="16x9">
                            <video ref={confirmVideoRef} controls playsInline aria-hidden="true" id="confirm_webcamera">
                                <track kind="captions" />
                                Your browser does not support the video tag.
                            </video>
                        </Ratio>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        再回答する
                    </Button>
                    <Button variant="primary" onClick={onNext}>
                        回答を終了して次へすすむ
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Step3;
